import {CheckoutNavigationService, ModalType} from '@wix/wixstores-client-storefront-sdk';
import {CreateCheckoutExceptions} from '../../common/constants';
import {ICart} from '../../types/app.types';

export const handleCreateCheckoutErrors = async (
  checkoutNavigationService: CheckoutNavigationService,
  isEditorX: boolean,
  cart: ICart,
  createCheckoutException: CreateCheckoutExceptions
): Promise<void> => {
  /* istanbul ignore else */
  if (createCheckoutException === CreateCheckoutExceptions.siteMustAcceptPayments) {
    checkoutNavigationService.sendLostBusinessEmail(false);
    await checkoutNavigationService.openModalByType(ModalType.NoOnlinePayments, isEditorX, cart);
  }
};
