import {PageMap} from '@wix/wixstores-client-core';
import {CustomUrlApi, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ICart, ICartProductsManifest} from '../../types/app.types';
import {SPECS} from '../specs';

export class ProductsService {
  private readonly siteStore: SiteStore;
  private readonly customUrlApi: CustomUrlApi;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.customUrlApi = new CustomUrlApi(siteStore.location.buildCustomizedUrl);
  }

  private readonly getHref = (slug: string, useCustomUrl: boolean, sectionUrl: string, pageUrl: string) => {
    if (this.siteStore.experiments.enabled(SPECS.UsePageUrlOnItemThumbnail) && pageUrl) {
      return pageUrl;
    }
    return useCustomUrl
      ? this.customUrlApi.buildProductPageUrl({slug})
      : /* istanbul ignore next */ `${sectionUrl}/${slug}`;
  };

  public async manifest(cart: ICart): Promise<ICartProductsManifest> {
    const [useCustomUrl, section] = await Promise.all([
      this.customUrlApi.init(),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
    ]);

    return cart.items.reduce<ICartProductsManifest>(
      (acc, item) => ({
        ...acc,
        [item.product.id]: {href: this.getHref(item.product.urlPart, useCustomUrl, section.url, item.product.pageUrl)},
      }),
      {}
    );
  }
}
