import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {StoreSettings} from '@wix/wixstores-graphql-schema-node';

export class StoreSettingsService {
  private readonly taxOnProduct: boolean = false;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.taxOnProduct = siteStore.priceSettings?.taxOnProduct ?? /* istanbul ignore next */ false;
  }

  public getFetchedData() {
    return {taxOnProduct: this.taxOnProduct} as StoreSettings;
  }
}
